import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import { intlReducer } from 'react-intl-redux'
import { createHashHistory } from 'history'

import amazonSellerAccounts from './amazon_seller_accounts'
import account from './account'
import analytics from './analytics'
import cardInfo from './card_info'
import categories from './categories'
import chatbot from './chatbot'
import checklist from './checklist'
import database from './database'
import extension from './extension'
import globalData from './global'
import historicalData from './historical_data'
import inventory from './inventory'
import keyword from './keyword'
import keywordEngine from './keyword_engine'
import keywordList from './keyword_list'
import keywordProducts from './keyword_products'
import product from './product'
import productCosts from './product_costs'
import registrations from './registrations'
import salesAnalytics from './sales_analytics'
import seats from './seats'
import sessions from './sessions'
import settings from './settings'
import sidebar from './sidebar'
import subscriptions from './subscriptions'
import suppliers from './suppliers'
import team from './team'
import tracker from './tracker'
import banners from './banners'

const staticReducers = {
  amazonSellerAccounts,
  account,
  analytics,
  banners,
  cardInfo,
  categories,
  chatbot,
  checklist,
  database,
  extension,
  globalData,
  historicalData,
  intl: intlReducer,
  inventory,
  keyword,
  keywordEngine,
  keywordList,
  keywordProducts,
  product,
  productCosts,
  registrations,
  salesAnalytics,
  seats,
  sessions,
  settings,
  sidebar,
  subscriptions,
  suppliers,
  team,
  tracker
}

// Dictionary to keep track of the registered async reducers
const asyncReducers = {}

function createRootReducer(overrideReducers = {}) {
  const { routerReducer } = createReduxHistoryContext({
    history: createHashHistory()
    // other options if needed
  })

  return combineReducers({
    router: routerReducer,
    ...staticReducers,
    ...asyncReducers,
    ...overrideReducers
  })
}

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
export const injectReducers = (store, reducers) => {
  reducers.forEach(({ key, reducer }) => {
    // don't redefine existing reducers
    if (asyncReducers[key]) {
      return
    }

    asyncReducers[key] = reducer
  })

  store.replaceReducer(createRootReducer())
}

export default createRootReducer
